<template>
  <v-dialog v-model="dialog" persistent width="400">
    <v-card>
      <v-card-title class="text-h5">Reset Password</v-card-title>
      <v-card-text
        ><p class="dialog-content">
          Are you sure you want to reset the password of selected operator?
        </p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="var(--primary)"
          dark
          :loading="loading"
          @click="resetOperatorPassword"
        >
          Confirm</v-btn
        >
        <v-btn color="grey darken-1" dark @click="close"> Cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['dialog', 'operator'],
  data: () => ({
    loading: false,
  }),
  methods: {
    close() {
      this.$emit('refresh')
      this.$emit('update:dialog', false)
    },
    async resetOperatorPassword() {
      this.loading = true
      try {
        let payload = {}
        payload.email = this.operator.email
        let response = await this.$store.dispatch(
          'reset_admin_password',
          payload
        )
        // console.log(response.data)
        this.$root.snackbar.show({
          message: response.data.message,
          color: 'success',
        })
        this.close()
      } catch (error) {
        this.$root.snackbar.show({
          message: error.response.data.message,
          color: 'error',
        })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-content {
  font-size: 16px;
}
</style>